import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import Navbar from './components/Navbar';
import { NavLink } from './components/styled/NavLink';
import Webbody from './components/Webbody';
import Backup from './routes/backup/Backup';

const lightTheme = {
    navbar: {
        backgroundColor: '#282c34',
        borderColor: 'grey',
        color: '#ffffff',
        height: '40px'
    },
    webbody: {
        backgroundColor: '#f5f5f5',
        color: 'black',
    },
    setup: {
        backgroundColor: '#f5f5f5',
        color: '#212121'
    },
    button: {
        backgroundColor: '#ffffff',
        borderColor: '#bbc0ca',
        color: '#212121'
    },
    select: {
        backgroundColor: '#f5f5f5',
        borderColor: '#bbc0ca',
        color: '#black'
    },
    backup: {
        backgroundColor: '#dbdbdb',
        color: '#212121'
    }
}

const darkTheme = {
    navbar: {
        backgroundColor: '#2e323e',
        borderColor: 'grey',
        color: '#ffffff',
        height: '40px'
    },
    webbody: {
        backgroundColor: '#383c4a',
        color: '#bbc0ca',
    },
    setup: {
        backgroundColor: '#2a2e3a',
        color: '#bbc0ca'
    },
    button: {
        backgroundColor: '#383c4a',
        borderColor: '#bbc0ca',
        color: '#ffffff'
    },
    select: {
        backgroundColor: '#383c4a',
        borderColor: '#bbc0ca',
        color: '#ffffff'
    },
    backup: {
        backgroundColor: '#2a2e3a',
        color: '#ffffff'
    }
}

function App() {

    const theme = darkTheme

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Navbar
                    height={theme.navbar.height}
                >
                    <NavLink to="/"> Backups </NavLink>
                </Navbar>

                <Webbody
                    height={theme.navbar.height}
                >
                    <Switch>
                        <Route path="/" exact component={Backup} />
                    </Switch>
                </Webbody>
            </Router>
        </ThemeProvider>
    );
}

export default App;
