import { Storage } from "../entities/Storage";
import CryptoJS from "crypto-js"
import { Backup } from "../entities/Backup";

export function getBackups(store: Storage): Promise<Backup[]> {
    return fetch(`${store.scheme}://${store.domain}${store.port != 80 ? ':' + store.port : ''}/images/mongo`, {
        method: 'GET',
        headers: {
            'Authorization': 'Basic ' + CryptoJS.enc.Base64.stringify(
                CryptoJS.enc.Utf8.parse(`${store.auth.username}:${store.auth.password}`)
            ),
        }
    })
        .then(res => res.json())
        .then(res => res as unknown as Backup[])
}

export function createBackup(store: Storage) {
    return fetch(`${store.scheme}://${store.domain}${store.port != 80 ? ':' + store.port : ''}/images/mongo`, {
        method: 'POST',
        headers: {
            'Authorization': 'Basic ' + CryptoJS.enc.Base64.stringify(
                CryptoJS.enc.Utf8.parse(`${store.auth.username}:${store.auth.password}`)
            ),
        }
    })
}

export function loadBackup(store: Storage, backup: Backup) {
    return fetch(`${store.scheme}://${store.domain}${store.port != 80 ? ':' + store.port : ''}/images/mongo/${backup.name.split(".")[0]}`, {
        method: 'POST',
        headers: {
            'Authorization': 'Basic ' + CryptoJS.enc.Base64.stringify(
                CryptoJS.enc.Utf8.parse(`${store.auth.username}:${store.auth.password}`)
            ),
        }
    })
}

export function deleteBackup(store: Storage, backup: Backup) {
    return fetch(`${store.scheme}://${store.domain}${store.port != 80 ? ':' + store.port : ''}/images/mongo/${backup.name.split(".")[0]}`, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Basic ' + CryptoJS.enc.Base64.stringify(
                CryptoJS.enc.Utf8.parse(`${store.auth.username}:${store.auth.password}`)
            ),
        }
    })
}