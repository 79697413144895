import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavLink = styled(Link)`
    text-decoration: none;
    padding: 7px 10px;
    margin-left: 5px;

    color: ${props => props.theme.navbar.color};

    &:hover {
        background-color: #00000021;
        border-radius: 5px;
    }

`