import styled from "styled-components";

const NavbarParent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid ${props => props.theme.navbar.borderColor};

    background-color: ${props => props.theme.navbar.backgroundColor};
    color: ${props => props.theme.navbar.color};
`

interface Props {
    height: string;
}

const Navbar: React.FunctionComponent<Props> = (props) => {
    return (
        <NavbarParent style={{
            height: props.height
        }}>
            <img src="logo.png" height={props.height} alt="logo" style={{
                marginLeft: "10px"
            }} />
            {props.children}
        </NavbarParent>
    );
}

export default Navbar;