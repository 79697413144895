import React from 'react'
import styled from 'styled-components';

const WebParent = styled.div`
    overflow: hidden;

    background-color: ${props => props.theme.webbody.backgroundColor};
    color: ${props => props.theme.webbody.color};
`

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    overflow-y: scroll;
    height: 100vh;
`

interface Props {
    height: string;
}

const Webbody: React.FunctionComponent<Props> = (props) => {
    return (
        <WebParent style={{
            height: 'calc(100vh - ' + props.height + ')'
        }}>
            <Wrapper>
                {props.children}
            </Wrapper>
        </WebParent>
    );
}

export default Webbody;