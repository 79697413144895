import React from 'react';
import { useHistory } from 'react-router';
import Alert from 'react-s-alert';
import styled from 'styled-components';
import { createBackup, deleteBackup, getBackups, loadBackup } from '../../endpoints/goba';
import { Backup as BackupEntity } from '../../entities/Backup';
import { Storage } from '../../entities/Storage';
import Setup from '../setup/Setup';

const BackupParent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-color: #303440;

    width: 100vw;
    max-height: calc(100vh - 50px - 70px - 70px);
    min-height: calc(100vh - 50px - 70px - 70px);
    overflow-y: scroll;
`

const BackupContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 50px;
    width: 500px;

    margin-top: 7px;
    margin-bottom: 7px;
    padding: 10px;

    background-color: ${props => props.theme.backup.backgroundColor};
    color: ${props => props.theme.backup.color};

    border-radius: 5px;

    i {
        padding: 10px;
        font-size: 1.2rem;
    }
`

const BackupControlls = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 500px;

    margin-top: 15px;
    margin-bottom: 15px;

    i {
        font-size: 1.5rem;
        padding-left: 10px;
        padding-right: 10px;
    }
`

const IconBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &:hover {
        color: white;
    }
`

const Icon = styled.i`
    color: #7b7b7b;

    &:hover {
        color: white;
    }
`

const Backup: React.FunctionComponent = () => {

    const [backups, setBackups] = React.useState<BackupEntity[]>([]);

    React.useEffect(() => {
        if (!localStorage.getItem('store'))
            return

        loadBackups()

        setInterval(() => {
            loadBackups()
        }, 2000)
    }, [])

    function getStorage(): Storage {
        var obj = JSON.parse(localStorage.getItem('store')!!);
        return obj as unknown as Storage;
    }

    function loadBackups() {
        getBackups(getStorage())
            .then(backups => {
                setBackups(backups)
            })
    }

    function create() {
        createBackup(getStorage()).then(rep => {
            if (rep.status > 299) {
                Alert.error('Could not create backup', {
                    position: 'bottom',
                    effect: 'slide',
                    timeout: 1500
                });
                return;
            }

            Alert.success('Backup successfully created', {
                position: 'bottom',
                effect: 'slide',
                timeout: 1500
            });

            loadBackups()
        })
    }

    function load(backup: BackupEntity) {
        loadBackup(getStorage(), backup).then(rep => {
            if (rep.status > 299) {
                Alert.error('Could not load backup', {
                    position: 'bottom',
                    effect: 'slide',
                    timeout: 1500
                });
                return;
            }

            Alert.success('Backup successfully loaded', {
                position: 'bottom',
                effect: 'slide',
                timeout: 1500
            });
        })
    }

    function _delete(backup: BackupEntity) {
        deleteBackup(getStorage(), backup).then(rep => {
            if (rep.status > 299) {
                Alert.error('Could not delete backup', {
                    position: 'bottom',
                    effect: 'slide',
                    timeout: 1500
                });
                return;
            }

            Alert.success('Backup successfully deleted', {
                position: 'bottom',
                effect: 'slide',
                timeout: 1500
            });

            setBackups(backups.filter(b => b.name !== backup.name))
        })
    }

    const renderSetup = () => {
        if (localStorage.getItem('store')) return null;
        return <Setup />
    }

    return (<>
        {renderSetup()}
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <BackupControlls>
                <IconBox onClick={() => {
                    setBackups([])
                    loadBackups()
                }}>
                    <i className='bx bx-refresh' ></i>
                    <span> Refresh </span>
                </IconBox>
                <IconBox onClick={() => {
                    backups.map(b => _delete(b))
                }}>
                    <i className='bx bx-trash'></i>
                    <span> Delete All </span>
                </IconBox>
                <IconBox onClick={() => {
                    create()
                }}>
                    <i className='bx bx-plus-circle'></i>
                    <span> Create </span>
                </IconBox>
            </BackupControlls>
        </div>
        <BackupParent>
            {backups && backups.map(backup => {
                return (
                    <BackupContainer key={backup.name}>
                        <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '80%'
                        }}>
                            <i className='bx bxs-data'></i>
                            {backup.name}
                        </div>
                        <div>
                            <Icon onClick={() => load(backup)} key="load" className='bx bx-upload' title={`load ${backup.name}`} />
                            <Icon onClick={() => _delete(backup)} key="delete" className='bx bx-x' title={`delete ${backup.name}`} />
                        </div>
                    </BackupContainer>
                )
            })}
        </BackupParent>
        <div>
            <p>
                gobaweb by <a style={{ color: 'white' }} href="https://git.micartey.dev/micartey/gobaweb" target="_blank"> micartey </a>
            </p>
        </div>
    </>);
}

export default Backup;