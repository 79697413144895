import styled from "styled-components";

export const Button = styled.button`
    width: 100%;
    height: 150%;
    min-height: 30px;

    background-color: ${props => props.theme.button.backgroundColor};
    color: ${props => props.theme.button.color};

    border: 1px solid ${props => props.theme.button.borderColor};
    border-radius: 10px;

    transition: all .1s ease-in-out;

    &:hover {
        background: linear-gradient(to right, #11998e, #38ef7d);
        transform: scale(1.1);
    }
`