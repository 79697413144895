import styled from "styled-components";

export const Input = styled.input`
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #9b9b9b;
    outline: 0;
    color: #fff;
    font-size: 1.3rem;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    font-weight: 80;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }

  &:focus {
    padding-bottom: 6px;
    border-width: 3px;
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
  }

  &:focus ~ {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #11998e;
    font-weight: 700;
  }

  &:required, &:invalid {
    box-shadow: none;
  }
`

export const InputLabel = styled.label`
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
`