import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Button } from '../../components/styled/Button';
import { Input, InputLabel } from '../../components/styled/Input';
import { Storage } from '../../entities/Storage';
import Alert from 'react-s-alert';
import { Select } from '../../components/styled/Select';

const SetupParent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: fixed;

    background-color: ${props => props.theme.setup.backgroundColor};
    color: ${props => props.theme.setup.color};

    height: fit-content;
    max-height: 500px;
    width: 500px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow-y: scroll;
    overflow-x: hidden;

    padding: 20px;
    border-radius: 10px;
    z-index: 2;
`

const SetupBackground = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);

    width: 200vw;
    height: 200vh;

    position: fixed;
    z-index: 1;
    left: 0;
`

const Setup: React.FunctionComponent = () => {

    const username: React.RefObject<HTMLInputElement> = React.createRef();
    const password: React.RefObject<HTMLInputElement> = React.createRef();
    const method: React.RefObject<HTMLSelectElement> = React.createRef();
    const host: React.RefObject<HTMLInputElement> = React.createRef();
    const port: React.RefObject<HTMLInputElement> = React.createRef();

    function save() {
        if (!username.current || !password.current || !host.current || !port.current || !method.current)
            return;

        if (username.current.value === '' || password.current.value === '' || host.current.value === '' || port.current.value === '') {
            Alert.error('Please fill out all fields.', {
                position: 'bottom',
                effect: 'slide',
                timeout: 1500
            });
            return;
        }

        var store: Storage = {
            auth: {
                username: username.current.value,
                password: password.current.value
            },
            scheme: method.current.value,
            domain: host.current.value,
            port: parseInt(port.current.value)
        }

        localStorage.setItem('store', JSON.stringify(store));

        window.open("/", "_self")
    }

    return (<>
        <SetupBackground />
        <SetupParent>
            <h1>Setup</h1>
            <p>
                Please enter your credentials to connect to the server. This is not a global setup and will only work for this device.
            </p>
            <h3>Authentication</h3>
            <Input ref={username} />
            <InputLabel>Username</InputLabel>
            <Input type="password" ref={password} />
            <InputLabel>Password</InputLabel>
            <br />
            <h3>Server</h3>
            <Input defaultValue={window.location.href.split("/")[2].split(":")[0]} ref={host} />
            <InputLabel>Domain</InputLabel>
            <Input ref={port} />
            <InputLabel>Port</InputLabel>
            <br />

            <h3>Advanced</h3>

            <p>
                Select the scheme this website is served on.
            </p>

            <Select ref={method}>
                <option value="http">HTTP</option>
                <option value="https">HTTPS</option>
            </Select>
            <InputLabel>Scheme</InputLabel>

            <br />
            <br />

            <Button onClick={() => save()}>Save</Button>

            <br />
            <br />
        </SetupParent>
    </>);
}

export default Setup;