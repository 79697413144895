import styled from "styled-components";

export const Select = styled.select`
    min-height: 30px;

    background-color: ${props => props.theme.select.backgroundColor};
    color: ${props => props.theme.select.color};

    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.2);
    appearance: none;
    outline: none;

    &:hover {
        box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.5);
    }
`